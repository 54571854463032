<div class="spb-holder">
  <spb-header></spb-header>
  <div class="spb-plain-content">
    <router-outlet></router-outlet>
  </div>
  <div class="spb-filler"></div>
  <spb-footer
    [copyrightYears]="copyrightYears"
    [version]="version" [license]="true"></spb-footer>
</div>
