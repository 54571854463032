import {ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {AppRoutingModule} from './app-routing.module'
import {LOCAL_STORAGE} from './application/localstorage.provider'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS,
  provideLuxonDateAdapter
} from '@angular/material-luxon-adapter'
import {MAT_DATE_FORMATS} from '@angular/material/core'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {authInterceptor} from './application/auth.interceptor'
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async'
import localeSv from '@angular/common/locales/sv'
import {registerLocaleData} from '@angular/common'

registerLocaleData(localeSv)


export default {
  providers: [
    importProvidersFrom(BrowserModule, AppRoutingModule),
    {
      provide: LOCAL_STORAGE,
      useValue: sessionStorage
    },
    // This must be provided in "app" for reasons I do not understand
    {provide: LOCALE_ID, useValue: 'sv-SE'},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        appearance: 'outline',
        color: 'primary',
        hideRequiredMarker: true,
        subscriptSizing: 'dynamic'
      }
    },
    // Only needed in user?
    provideLuxonDateAdapter(),
    {provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true, firstDayOfWeek: 1}},
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    provideHttpClient(withInterceptors([authInterceptor])),
    provideAnimationsAsync()
  ]
} satisfies ApplicationConfig