import {inject, Injectable} from '@angular/core'
import {map, Observable, ReplaySubject} from 'rxjs'
import {HttpClient} from '@angular/common/http'

interface IClosed {
  closed: {
    start: string
    end: string
  }
}

@Injectable({
  providedIn: 'root'
})
export class ClosedService {
  private http = inject(HttpClient)

  /**
   * If not overwritten we are open. These are
   * available for outsiders as well.
   */
  public start = '2034-12-31T12:00:00'
  public end = '1970-01-01T00:00:00'

  public closed$ = new ReplaySubject<boolean>(1)

  constructor() {
    // Must cache bust this one.
    this.http.get<IClosed>(`assets/package.json?ts=${Date.now()}`).subscribe({
      next: (res) => {
        const data = Object.assign({closed: {}}, res)
        const start = new Date(data.closed.start)
        if (!isNaN(start.getTime())) {
          this.start = data.closed.start
        }
        const end = new Date(data.closed.end)
        if (!isNaN(end.getTime())) {
          this.end = data.closed.end
        }

        this.closed$.next(true)
      }
    })
  }

  public isClosed(): Observable<boolean> {
    return this.closed$.pipe(
      map(() => {
        const closeStart = new Date(this.start)
        const closeEnd = new Date(this.end)
        const now = Date.now()
        return now > closeStart.getTime() && now < closeEnd.getTime()
      })
    )

  }
}
